$(function () {
    $('.toggle-control').on('click', function (event) {
        event.preventDefault();
        var $control = $(this);
        var expanded = $control.attr('data-expanded');
        var $target = $($control.data('target'));
        var targetClass = $control.data('target-class');

        if (expanded === 'true') {
            $control.attr('data-expanded', "false");
            $target.addClass(targetClass);
        } else {
            $control.attr('data-expanded', "true");
            $target.removeClass(targetClass);
        }
    });

    var $navigationWrap = $('.navigation-wrap');
    $navigationWrap.each(function () {
        var $wrap = $(this);
        var $body = $('body');

        $wrap.find('.navigation-button').on('click', function () {
            $wrap.addClass('navigation-wrap_active');

            $body.on('click.main-menu', function (event) {
                var $target = $(event.target);
                var $menuLink = $();
                if ($target.hasClass('navigation__link')) {
                    $menuLink = $target;
                } else if ($target.parents('.navigation__link:first').length) {
                    $menuLink = $target.parents('.navigation__link:first');
                }

                if ($menuLink.length || !$target.parents('.navigation-wrap:first').length) {
                    $wrap.removeClass('navigation-wrap_active');
                    $body.off('click.main-menu');
                }
            });
        });
    });

    $('.sidebar-menu-wrap').each(function () {
        var $menuWrap = $(this);
        var $menu = $menuWrap.find('.sidebar-menu');
        var $menuItems = $menu.find('.sidebar-menu__link');
        var activeClass = 'sidebar-menu__link_active';
        var $burger = $menuWrap.find('.sidebar-menu-burger');

        $burger.on('click', function (event) {
            event.preventDefault();
            $menu.slideToggle(function () {
                var $menu = $(this);
                $menu.toggleClass('sidebar-menu_active');

                if ($menu.hasClass('sidebar-menu_active')) {
                    $menuItems.on('click.burger', function () {
                        $menu.slideUp(function () {
                            $(this).removeClass('sidebar-menu_active');
                        });
                        $menuItems.off('click.burger');
                    });
                }
            });
        });

        $menuItems.on('click', function () {
            var $item = $(this);
            if (!$item.hasClass(activeClass)) {
                var pageSelector = $item.attr('href').substr($item.attr('href').indexOf('#'));
                if (pageSelector !== '' && pageSelector !== '#') {
                    $menuItems.removeClass(activeClass);
                    $item.addClass(activeClass);

                    $('html, body').stop().animate({
                        scrollTop: 0
                    }, 1000);

                    $('.static-page_active').slideUp(function () {
                        $(this).removeClass('static-page_active')
                    });

                    $(pageSelector).slideDown(function () {
                        $(this).addClass('static-page_active');
                    });
                }
            }
        });

        var pageHash = location.hash;
        if (pageHash !== '' && pageHash !== '#') {
            $menuItems.filter('[href="' + pageHash + '"]').click();
        }
    });
});